import styled from "styled-components"

export const Main = styled.div`
  margin-top: 10rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 83.3%;

  @media (max-width: 1149px) {
    width: 82.5%;
  }

  @media (max-width: 799px) {
    width: 88.88%;
  }

  p {
    a {
      text-decoration: none;

      u {
        text-decoration: none;
        text-decoration: underline;
      }
    }
  }

  p,
  h2,
  u,
  ol {
    color: #fdfdfd;
  }

  p,
  u,
  b,
  li,
  ol,
  ul {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: 0.06em;
  }

  h2 {
    font-size: 1.7rem;
    font-weight: 600;
    line-height: 3rem;
    letter-spacing: 0.06em;
  }

  li {
    margin-left: 30px;
  }

  b {
    font-size: 1.2rem;
    font-weight: 500;
  }

  ol {
    margin-top: 5px;
  }
`
