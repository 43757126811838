import React, { useEffect, useState } from "react"
import { useIntl } from "gatsby-plugin-react-intl"
import { useStaticQuery, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { Main, Wrapper } from "./style"

const isBrowser = typeof window !== "undefined"
const Nav = () => {
  const intl = useIntl()
  const [data, setData] = useState({})

  const query = useStaticQuery(graphql`
    query RodoFull {
      polish: contentfulRodo(node_locale: { eq: "pl-PL" }) {
        rodo {
          raw
        }
      }
      english: contentfulRodo(node_locale: { eq: "en-US" }) {
        rodo {
          raw
        }
      }
    }
  `)

  useEffect(() => {
    if (isBrowser) {
      if (intl.locale === "en") {
        setData(query.english)
      }
      if (intl.locale === "pl") {
        setData(query.polish)
      }
    }
  }, [intl])

  return (
    <Main>
      <Wrapper>{data.rodo && renderRichText(data.rodo)}</Wrapper>
    </Main>
  )
}

export default Nav
